<template>
  <div>
    <!--Overlay Effect-->
    <div v-if="dialog" class="fixed inset-0 bg-black bg-opacity-80 overflow-y-auto h-full w-full z-50 block"
      id="modal-payment">
      <div class="w-full h-full absolute" @click="cancel()"></div>
      <!--modal content-->
      <div class="relative top-1/2 transform -translate-y-1/2 mx-auto max-w-2xl bg-white rounded-2xl">
        <div class="flex">
          <div class="items-center w-64 flex-none mx-6">
            <div class="flex items-center justify-between my-2">
              <h3 class="text-xl font-medium">QR Code</h3>
              <!-- <font-awesome-icon icon="fa-solid fa-share-nodes" /> -->
            </div>
            <div class="h-64 bg-51 mb-5 flex items-center">
              <qrcode :value="qrCode" :options="{ width: 300 }" tag="img" class="w-full"></qrcode>
            </div>
          </div>
          <div class="w-full ml-1.5 mr-6 items-center">
            <div class="my-3 justify-end w-full flex">
              <span class="flex items-center cursor-pointer text-xl" @click="cancel()">
                <font-awesome-icon icon="fa-solid fa-x" />
              </span>
            </div>
            <div class="flex flex-col justify-between ml-1.5 mr-6 mb-5 font-medium h-64">
              <div class="leading-relaxed mb-3 text-lg">
                <p class="text-sm">Event Name</p>
                <h2 class="text-xl font-semibold">{{tTt}}</h2>
              </div>
              <div class="leading-relaxed mb-3">
                <p class="text-sm">Date</p>
                <p><span class="mr-2">
                    <font-awesome-icon icon="fa-regular fa-calendar" size="lg" />
                  </span>{{$moment(tDt).format("dddd, D MMMM YYYY")}}</p>
                <p><span class="mr-2">
                    <font-awesome-icon icon="fa-regular fa-clock" size="lg" />
                  </span>{{tTm}} WIB</p>
              </div>
              <div class="leading-relaxed">
                <p class="text-sm">Location</p>
                <div class="flex">
                  <span class="mr-2">
                    <font-awesome-icon icon="fa-solid fa-location-dot" />
                  </span>
                  <div class="items-start">{{tLc}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Content End -->
  </div>
</template>

<script>
export default {
  name: 'DetailTicketEvent',
  props: {
    dataRegis: {
      type: Object,
      required: true,
    },
    tTt: {
      type: String,
      required: true,
    },
    tDt: {
      type: String,
      required: true,
    },
    tTm: {
      type: String,
      required: true,
    },
    tLc: {
      type: String,
      required: true,
    },
  },
  components: {
  },
  data() {
    return {
      qrCode: "",
      dialog: false
    }
  },
  methods: {
    async open() {
      var form = new FormData();
      form.append("txtId", this.dataRegis.qr_code);
      this.qrCode = this.dataRegis.qr_code;
      const res = await this.$http.post(`${this.$apiTripwe}/ic/jetski-android-new/lihat_data_encode.php`, form);
      if(res.data != null || res.data != ""){
        this.qrCode = res.data;
      }
      this.dialog = true
    },
    cancel() {
      this.dialog = false
    },
  }
}
</script>